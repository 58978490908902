import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const lpgOrderHistory: IMcAppType = {
  name: "lpgOrderHistory",
  path: "/my/service/lpg/order-history",
  description: "LPG order history",
  navFlow: NavFlow.FOCUS,
  title: "Order history",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "lpgOrderHistory",
  },
};
