import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const { accountNumber } = CommonParameters;

export const refunds: IMcAppType = {
  name: "refunds",
  path: "/my/refunds",
  parameters: {
    properties: { accountNumber },
  },
  description: "Transfer or refund credit",
  navFlow: NavFlow.STANDARD,
  title: "Transfer or refund my credit",
  parent: Tab.MORE,
  conditions: {
    intent: "refunds",
    customerType: CustomerType.kraken,
  },
};

export const refundsRequest: IMcAppType = {
  name: "refundsRequest",
  path: "/my/refunds/request",
  parameters: {
    properties: { accountNumber },
  },
  description: "Transfer or refund credit",
  navFlow: NavFlow.FOCUS,
  title: "Transfer or refund my credit",
  parent: Tab.MORE,
  conditions: {
    intent: "refundsRequest",
    customerType: CustomerType.kraken,
  },
};
