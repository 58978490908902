import {
  IAppDiagnostics,
  IAsyncMessengerClose,
  IAsyncMessengerOpen,
  IAuthUser,
  IAuthUserResult,
  IChatMessengerHide,
  IChatMessengerShow,
  ICloseModal,
  ICloseSurvey,
  IDeviceWalletOpen,
  IDeviceWalletSupported,
  IFetchAccessToken,
  IHttpReqError,
  ILinkEvAccount,
  INavClose,
  INavHref,
  INavHrefTitle,
  INavTo,
  INavToParams,
  INavToUrl,
  IOpenResource,
  IPlatformParameters,
  IPlatformReadiness,
  IShowModal,
  IShowToast,
  ISurveyInit,
  MCAPPS,
  NavCompleteTopics,
  TOPICS,
  eventDispatcher,
} from "@origin-digital/event-dispatcher";
import { IAppRoute, getAppRoute } from "@origin-digital/mcapp-registry";
import { isLoggedIn } from "@origin-digital/origin-auth";
import {
  Channel,
  CustomerType,
  EnvironmentNames,
  NavFlow,
} from "@origin-digital/platform-enums";
import { logger } from "@origin-digital/reporting-client";
import {
  IPlatformHandlerProps,
  PlatformHandler,
  SubscribeTopics,
} from "../mesh.types";
import { getJwt } from "../services/auth";
import {
  appendURLHost,
  browserNav,
  emitAppDiagnostics,
  historyBack,
  openInNewTab,
  windowExists,
} from "./helpers";
import {
  attachOnPopStateCallback,
  recordNavComplete,
} from "./navigationStateHandler";
import { fetchPlatformParameters } from "./platformParameters";
import {
  addNavToReferrerPath,
  deleteNavToPromise,
  getNavToReferralPath,
  updateNavToPromise,
} from "./storage";

export class WebPlatformHandler implements PlatformHandler {
  private environment: EnvironmentNames;
  private navHefDepWarn = false;

  constructor({ environment }: IPlatformHandlerProps) {
    this.environment = environment;
    this.listener = this.listener.bind(this);
    this.fetchAppRoute = this.fetchAppRoute.bind(this);
    this[TOPICS.FETCH_ACCESS_TOKEN] =
      this[TOPICS.FETCH_ACCESS_TOKEN].bind(this);
    this[TOPICS.FETCH_PLATFORM_PARAMETERS] =
      this[TOPICS.FETCH_PLATFORM_PARAMETERS].bind(this);
    this[TOPICS.SURVEY_INIT] = this[TOPICS.SURVEY_INIT].bind(this);
    this[TOPICS.SURVEY_CLOSE] = this[TOPICS.SURVEY_CLOSE].bind(this);

    attachOnPopStateCallback(() => this[TOPICS.NAV_CLOSE]());
  }

  public listener(event: SubscribeTopics): any {
    return this[event.topic](event.payload as any);
  }

  public [TOPICS.FETCH_ACCESS_TOKEN](
    payload: IFetchAccessToken["payload"]
  ): IFetchAccessToken["result"] {
    return getJwt(
      (payload as { environment: EnvironmentNames }).environment ||
        this.environment
    );
  }

  public [TOPICS.AUTH_USER](
    payload: IAuthUser["payload"]
  ): IAuthUserResult["result"] {
    return isLoggedIn({
      auth0Environment:
        (payload as { auth0Environment: EnvironmentNames }).auth0Environment ||
        this.environment,
    })
      .then((isAuth) => ({ isAuth }))
      .catch((error) => {
        logger.debug(
          "[od/mesh] Something went wrong during async isLoggedIn check",
          { error, payload }
        );

        return { isAuth: false };
      });
  }

  public [TOPICS.NAV_CLOSE](payload?: INavClose["payload"]): Promise<void> {
    const result = getNavToReferralPath();
    if (result) {
      deleteNavToPromise(result.navToKey);
    }

    if (payload && payload.dispatchNavTo) {
      // remembers the referrerPath if defined
      return this[TOPICS.NAV_TO]({
        ...payload.dispatchNavTo,
        parameters: {
          referrerPath: result && result.referrerPath,
          ...payload.dispatchNavTo.parameters,
        },
      } as unknown as INavTo["payload"]);
    } else if (result) {
      browserNav(result.referrerPath);
    } else {
      return this[TOPICS.NAV_TO]({
        to: MCAPPS.home,
      });
    }
    return Promise.resolve();
  }

  public [TOPICS.NAV_COMPLETE](payload: NavCompleteTopics["payload"]): void {
    const { from, result } = payload;
    if (!from) {
      logger.warn(
        "[od/mesh]: deprecated please update navigationComplete() to navComplete[...]()"
      );
    }
    recordNavComplete();
    updateNavToPromise(from, {
      resolvable: false,
      navStatus: "success",
      result,
    });
  }

  private fetchAppRoute(payload: INavTo["payload"]): Promise<IAppRoute> {
    return this[TOPICS.FETCH_PLATFORM_PARAMETERS]().then((parameters) => {
      return getAppRoute(parameters, payload);
    });
  }

  public [TOPICS.FETCH_PLATFORM_PARAMETERS](): Promise<IPlatformParameters> {
    return fetchPlatformParameters(this[TOPICS.FETCH_ACCESS_TOKEN]({})).then(
      (jwtParameters) => ({ channel: Channel.WEB, ...jwtParameters })
    );
  }

  /**
   * record getNavToReferralPath
   * referrerPath if set or window.location.href
   */
  public async [TOPICS.NAV_TO](payload: INavTo["payload"]): Promise<void> {
    const { url, navFlow } = await this.fetchAppRoute(payload);
    if (navFlow === NavFlow.EXTERNAL) {
      openInNewTab(url);
      return;
    }
    const parameters = payload.parameters as Pick<INavToParams, "referrerPath">;
    const referrerPath = parameters ? parameters.referrerPath : undefined;
    addNavToReferrerPath(payload.to, referrerPath);
    browserNav(url);
  }

  public [TOPICS.NAV_TO_URL](payload: INavToUrl["payload"]): void {
    const { url, externalBrowser } = payload;
    const fullUrl = appendURLHost(url);
    if (externalBrowser) {
      openInNewTab(fullUrl);
    } else {
      browserNav(fullUrl);
    }
  }

  public [TOPICS.NAV_BACK](): void {
    historyBack();
  }

  /** @deprecated use NAV_HREF_TITLE */
  public [TOPICS.NAV_HREF](payload: INavHref["payload"]): INavHref["result"] {
    if (!this.navHefDepWarn) {
      logger.warn("[@od/mesh] NAV_HREF is deprecated, use NAV_HREF_TITLE", {
        payload,
      });
      this.navHefDepWarn = true;
    }
    const { url, title } = getAppRoute(
      {
        customerType: CustomerType.kraken, // assumption
        scopedToken: false, // assumption
        channel: Channel.WEB,
        backends: [],
      },
      payload
    );
    return { href: url, title };
  }

  // copy of native
  public [TOPICS.NAV_HREF_TITLE](
    payload: INavHrefTitle["payload"]
  ): INavHrefTitle["result"] {
    return this.fetchAppRoute(payload).then(({ url, title }) => ({
      href: url,
      title,
    }));
  }

  public [TOPICS.HTTP_REQ_ERROR](payload: IHttpReqError["payload"]): void {
    logger.debug(
      "[od/mesh] HTTP_REQ_ERROR feature is not supported in web",
      payload
    );
  }

  public [TOPICS.OPEN_RESOURCE](payload: IOpenResource["payload"]): void {
    const { webTarget, url } = payload;
    if (webTarget === "sameTab") {
      browserNav(url);
    } else {
      openInNewTab(payload.url);
    }
  }

  public [TOPICS.TOAST_SHOW](payload: IShowToast["payload"]): void {
    logger.debug(
      "[od/mesh] TOAST_SHOW feature is not supported in web",
      payload
    );
  }

  public [TOPICS.DEVICE_WALLET_SUPPORTED](
    payload: IDeviceWalletSupported["payload"]
  ): IDeviceWalletSupported["result"] {
    logger.debug(
      "[od/mesh] device wallet feature is not supported in web",
      payload
    );
    // Return correctly shaped false types to make handling easier
    return Promise.resolve({ applePay: false, googlePay: false });
  }

  public [TOPICS.DEVICE_WALLET_OPEN](
    payload: IDeviceWalletOpen["payload"]
  ): void {
    logger.debug(
      "[od/mesh] device wallet feature is not supported in web",
      payload
    );
  }

  public [TOPICS.MODAL_OPEN](payload: IShowModal["payload"]): void {
    logger.debug(
      "[od/mesh] MODAL_OPEN feature is not supported in web",
      payload
    );
  }

  public [TOPICS.MODAL_CLOSE](payload: ICloseModal["payload"]): void {
    logger.debug(
      "[od/mesh] MODAL_OPEN feature is not supported in web",
      payload
    );
  }

  public [TOPICS.SURVEY_INIT](payload: ISurveyInit["payload"]): void {
    eventDispatcher.dispatch({ topic: TOPICS.SURVEY_DIALOG_OPEN, payload });
  }

  public [TOPICS.SURVEY_CLOSE](payload: ICloseSurvey["payload"]): void {
    if (window && window.top) {
      window.top.postMessage({ topic: TOPICS.SURVEY_CLOSE, payload }, "*");
    } else {
      logger.warn("[@od/mesh] no parent document found for survey", payload);
    }
  }

  public [TOPICS.CHAT_MESSENGER_SHOW](
    payload: IChatMessengerShow["payload"]
  ): void {
    if (windowExists && typeof window.Intercom === "function") {
      window.Intercom("show");
    } else {
      logger.warn("[@od/mesh] Intercom not initialised", payload);
    }
  }

  public [TOPICS.CHAT_MESSENGER_HIDE](
    payload: IChatMessengerHide["payload"]
  ): void {
    if (windowExists && typeof window.Intercom === "function") {
      window.Intercom("hide");
    } else {
      logger.warn("[@od/mesh] Intercom not initialised", payload);
    }
  }

  public [TOPICS.FETCH_NATIVE_FEATURES](): Promise<{
    features: Record<string, never>;
    appInfo?: Record<string, never>;
  }> {
    return Promise.resolve({ features: {}, appInfo: {} });
  }

  public [TOPICS.ASYNC_MESSENGER_OPEN](
    payload: IAsyncMessengerOpen["payload"]
  ): void {
    if (windowExists && typeof window.Intercom === "function") {
      window.Intercom("show");
    } else {
      logger.warn("[@od/mesh] Intercom not available", payload);
    }
  }

  public [TOPICS.ASYNC_MESSENGER_CLOSE](
    payload: IAsyncMessengerClose["payload"]
  ): void {
    if (windowExists && typeof window.Intercom === "function") {
      window.Intercom("hide");
    } else {
      logger.warn("[@od/mesh] Intercom not available", payload);
    }
  }

  public [TOPICS.PLATFORM_READINESS](
    payload: IPlatformReadiness["payload"]
  ): void {
    logger.debug(
      "[od/mesh] PLATFORM_READINESS should only be used in the native channel",
      payload
    );
  }

  public [TOPICS.APP_DIAGNOSTICS](payload: IAppDiagnostics["payload"]): void {
    emitAppDiagnostics(payload, Channel.WEB);
  }

  public [TOPICS.LINK_EV_ACCOUNT](payload: ILinkEvAccount["payload"]): void {
    logger.debug(
      "[od/mesh] LINK_EV_ACCOUNT should only be used in the native channel",
      payload
    );
  }
}

let instance: PlatformHandler;
export const webPlatformHandler = (
  props: IPlatformHandlerProps
): PlatformHandler => {
  if (!instance) {
    instance = new WebPlatformHandler(props);
  }
  return instance;
};
