import * as React from "react";
import {
  OriginThemeProvider,
  TrackingProvider,
} from "@origin-digital/ods-core";
import { LinkComponentProps } from "@origin-digital/ods-types";
import { trackingProviderCaptureClick } from "@origin-digital/reporting-client";
import { parseMarkdownEvent } from "@origin-digital/event-dispatcher";

import { PrefetchedContent } from "src/components/prefetch";
import Breadcrumbs, { getBreadcrumbsContent } from "../components/Breadcrumbs";
import {
  FontStyles,
  ResetStyles,
  IconFontStyles,
  BrazeStyles,
} from "../styles/global";
import { UnauthAppContent } from "../components/AppContent";
import { SuppressAndroidInstallPrompt } from "../components/SuppressAndroidInstallPrompt";
import { CrisisBanner } from "../components/Banner";

const MeshLink = React.forwardRef<any, LinkComponentProps>((props, ref) => {
  const { href, onClick } = parseMarkdownEvent(props.href);
  const { children, ...rest } = props;
  return (
    <a
      ref={ref}
      {...rest}
      href={href}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        return rest.onClick && rest.onClick(e);
      }}
    >
      {children}
    </a>
  );
});

//TODO: Type this exactly to match what tal-core passes on.
export interface AppProps {
  children?: React.ReactNode;
  showNativeAppAd?: boolean;
  setupFonts?: boolean;
  showHeader?: boolean | "unauth" | "scoped";
  prefetchContent?: Record<string, PrefetchedContent>;
  enableCrisisBanner?: boolean;
}

export const CMSHeader = ({
  showNativeAppAd = true,
  showHeader = true,
  setupFonts = false,
  prefetchContent = {},
  enableCrisisBanner = true,
}: AppProps) => {
  const breadcrumbsContent = getBreadcrumbsContent(prefetchContent);

  return (
    <>
      {setupFonts && (
        <>
          <FontStyles />
          <ResetStyles />
          <IconFontStyles />
          <BrazeStyles />
        </>
      )}

      <OriginThemeProvider
        //@ts-ignore fails when updating to ts 4
        linkComponent={MeshLink}
        generatorClassNameOptions={{ seed: "tal" }}
      >
        <TrackingProvider onTrackingCapture={trackingProviderCaptureClick}>
          <div>
            {enableCrisisBanner && <CrisisBanner />}
            {!showNativeAppAd && <SuppressAndroidInstallPrompt />}
            <UnauthAppContent
              showHeader={showHeader}
              title=""
              actionBar={{ hidden: true }}
              enableCrisisBanner={enableCrisisBanner}
            />
            {breadcrumbsContent && <Breadcrumbs data={breadcrumbsContent} />}
          </div>
        </TrackingProvider>
      </OriginThemeProvider>
    </>
  );
};
