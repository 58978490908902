/**
 * If this library is imported, it will emulate the Odin native environment.
 *   - navTo - opens Focus Flow & Nav Flows in new tabs
 *   - navClose - closes the current tab
 *   - openResource - will work
 *
 *  Usage:
 *
 *   In the root of your McApp project include
 *
 *   // DEV MODE ONLY
 *   import { nativeEmulator } from '@origin-digital/native-features';
 *   if (__DEV__) nativeEmulator();
 *
 *   ensure isNative() is true, either :
 *    - add ?native=true to the query string
 *    - change the user-agent to include 'odin-mobile-app'
 *
 */

import { logger } from "@origin-digital/reporting-client";

import {
  FEATURES,
  IFeatureBase,
  IFetchAccessTokenFeature,
  IHttpReqError,
  INavExternalAuthFeature,
  INavExternalFeature,
  INavFocusFeature,
  INavModalFeature,
  INavStandardFeature,
  INavTabFeature,
  IOpenResourceFeature,
  IReauthenticateFeature,
  ISpinnerFeature,
  IViewPdfFeature,
  IToastFeature,
  IShowModalFeature,
  ICloseModalFeature,
  ILinkEvAccountFeature,
  IDeviceWalletSupportedFeature,
  IDeviceWalletOpenFeature,
} from "./features.type";

export const NATIVE_EMU_QUERY_STRING_IDENTIFIER = "_nativeEmulator";

// from dynamicConfig
const tabs = [
  {
    id: "dashboard",
    title: "Dashboard",
    actionBarTitle: "My Account",
    path: "/my",
    icon: "dashboard",
  },
  {
    id: "rewards",
    title: "Rewards",
    actionBarTitle: "Rewards",
    path: "/my/reward/rewards-and-offers",
    icon: "tag-heart",
    iconType: "materialCommunityIcons",
  },
  {
    id: "help",
    title: "Help",
    actionBarTitle: "Help & Support",
    path: "/help-support",
    icon: "help-outline",
  },
  {
    id: "more",
    title: "More",
    path: "",
    icon: "more-horiz",
  },
];

const features = {
  features: Object.values(FEATURES),
  appInfo: {
    version: "1.alpha.test",
    platform: "nativeEmulator",
    userAgent: "nativeEmulator; odin",
  },
};

const dispatchFeatureEvent = (): void => {
  document.dispatchEvent(new MessageEvent("message", { data: features }));
};

// https://stackoverflow.com/questions/5999118/how-can-i-add-or-update-a-query-string-parameter
function updateQueryString(key: string, value: string, url: string): string {
  const re = new RegExp(`([?&])${key}=.*?(&|#|$)(.*)`, "gi");
  let hash: string[];

  if (re.test(url)) {
    if (typeof value !== "undefined" && value !== null) {
      return url.replace(re, `$1${key}=${value}$2$3`);
    } else {
      hash = url.split("#");
      url = hash[0].replace(re, "$1$3").replace(/(&|\?)$/, "");
      if (typeof hash[1] !== "undefined" && hash[1] !== null) {
        url += `#${hash[1]}`;
      }
      return url;
    }
  } else if (typeof value !== "undefined" && value !== null) {
    const separator = url.indexOf("?") === -1 ? "?" : "&";
    hash = url.split("#");
    url = `${hash[0] + separator + key}=${value}`;
    if (typeof hash[1] !== "undefined" && hash[1] !== null) {
      url += `#${hash[1]}`;
    }
    return url;
  } else {
    return url;
  }
}

function addNativeEmu(url: string): string {
  if (window?.oetal?.pageData.channel === "native") {
    url = updateQueryString("native", "true", url);
  }
  return updateQueryString(NATIVE_EMU_QUERY_STRING_IDENTIFIER, "true", url);
}

const handler: Record<FEATURES, (data: any) => void> = {
  requestAppInfo: () => dispatchFeatureEvent(),
  navBack: () => history.back(),
  navExternal: (data: INavExternalFeature["data"]) => {
    window.open(data.url);
  },
  navExternalAuth: (data: INavExternalAuthFeature["data"]) => {
    window.open(data.url);
  },
  navFocus: (data: INavFocusFeature["data"]) => {
    if (data.show) {
      window.open(addNativeEmu(data.url), "focus");
    } else {
      window.close();
    }
  },
  navModal: (data: INavModalFeature["data"]) => {
    if (data.show) {
      window.open(addNativeEmu(data.url), "modal");
    } else {
      window.close();
    }
  },
  navTab: (data: INavTabFeature["data"]) => {
    const tab = tabs.find((t) => t.id === data.tab);
    if (tab) {
      window.open(addNativeEmu(tab.path), tab.id);
    }
  },
  navStandard: (data: INavStandardFeature["data"]) => {
    window.location.assign(addNativeEmu(data.url));
  },
  navClose: () => {
    window.close();
  },
  openResource: (data: IOpenResourceFeature["data"]) => {
    window.open(addNativeEmu(data.url));
  },
  spinner: (data: ISpinnerFeature["data"]) =>
    logger.error("[nativeEmulator]: spinner not supported", data),

  viewpdf: (data: IViewPdfFeature["data"]) => window.open(data.url),
  httpReqError: (data: IHttpReqError["data"]) =>
    logger.error("[nativeEmulator]: httpReqError not supported", data),
  reauthenticate: (data: IReauthenticateFeature["data"]) =>
    logger.error("[nativeEmulator]: reauthenticate not supported", data),
  fetchAccessToken: (data: IFetchAccessTokenFeature["data"]) =>
    logger.error("[nativeEmulator]: fetchAccessToken not supported", data),

  toast: (data: IToastFeature["data"]) =>
    logger.error("[nativeEmulator]: toast not supported", data),
  showModal: (data: IShowModalFeature["data"]) =>
    logger.error("[nativeEmulator]: modal not supported", data),
  closeModal: (data: ICloseModalFeature["data"]) =>
    logger.error("[nativeEmulator]: modal not supported", data),
  asyncMessaging: () =>
    logger.error("[nativeEmulator]: asyncMessaging feature not supported"),
  platformReadiness: () =>
    logger.error("[nativeEmulator]: platformReadiness feature not supported"),
  linkEvAccount: (data: ILinkEvAccountFeature["data"]) =>
    logger.error("[nativeEmulator]: linkEvAccount feature not supported", data),
  deviceWalletSupported: (data: IDeviceWalletSupportedFeature["data"]) =>
    logger.error("[nativeEmulator]: deviceWallet feature not supported", data),
  deviceWalletOpen: (data: IDeviceWalletOpenFeature["data"]) =>
    logger.error("[nativeEmulator]: deviceWallet feature not supported", data),
};

export const initNativeEmulator = (): void => {
  logger.info("[nativeEmulator]: enabled");
  window.addEventListener(
    "message",
    (event) => {
      if (event.data && typeof event.data === "string") {
        const payload = JSON.parse(event.data) || {};
        const { action, data } = payload as IFeatureBase;
        if (!action) return;
        if (handler[action]) {
          logger.debug("[nativeEmulator] postMessage to odin", payload);
          handler[action](data);
        } else {
          logger.debug(
            "[nativeEmulator] no native handler for action",
            payload
          );
          return;
        }
      }
      if (
        (event.data && event.data.source === "react-devtools-bridge") ||
        (event.data && event.data.source === "react-devtools-content-script")
      ) {
        // do nothing
      } else {
        logger.trace("[nativeEmulator]: message ignored", event);
      }
    },
    false
  );
  dispatchFeatureEvent();
  const userAgent = navigator.userAgent;
  (navigator as any).__defineGetter__(
    "userAgent",
    () => `${userAgent} odin-mobile-app odin-native-emulator`
  );
};
