//NOTE: keep this file in sync with feature.type.ts file in odin-mobile-appName
//https://bitbucket.org/origin-digital/odin-mobile-app/src/main/src/helpers/features.type.ts

import {
  IOpenResource,
  IShowModal,
  ICloseModal,
  IPlatformReadiness,
  ILinkEvAccount,
  IDeviceWalletOpen,
  IDeviceWalletSupported,
} from "@origin-digital/event-dispatcher";
import { Tab } from "@origin-digital/platform-enums";

// eslint-disable-next-line no-shadow
export enum FEATURES {
  requestAppInfo = "requestAppInfo",
  spinner = "spinner",
  viewpdf = "viewpdf",
  navClose = "navClose",
  navFocus = "navFocus",
  navModal = "navModal",
  navStandard = "navStandard",
  navTab = "navTab",
  navExternal = "navExternal",
  navExternalAuth = "navExternalAuth",
  httpReqError = "httpReqError",
  reauthenticate = "reauthenticate", // TODO remove, UPDATED TO "httpReqError" May 26th
  openResource = "openResource",
  navBack = "navBack",
  fetchAccessToken = "fetchAccessToken",
  toast = "toast",
  showModal = "showModal",
  closeModal = "closeModal",
  asyncMessaging = "asyncMessaging",
  platformReadiness = "platformReadiness",
  linkEvAccount = "linkEvAccount",
  deviceWalletSupported = "deviceWalletSupported",
  deviceWalletOpen = "deviceWalletOpen",
}

export type FeaturesType = keyof typeof FEATURES;

export type FeatureDetectionType = { [key in FeaturesType]: boolean };

export interface IFeatureBase {
  action: FeaturesType;
  data?: any;
}

export interface IFetchAccessTokenFeature extends IFeatureBase {
  action: typeof FEATURES.fetchAccessToken;
  data: {
    environment: string;
    messageId: string;
  };
}

export interface IViewPdfFeature extends IFeatureBase {
  action: typeof FEATURES.viewpdf;
  data: {
    url: string;
    title?: string;
    resourceType?: "url" | "file" | "base64";
  };
}

export interface IRequestAppInfoFeature extends IFeatureBase {
  action: typeof FEATURES.requestAppInfo;
}

/**
 * shows the native spinner for the given time in ms
 */
export interface ISpinnerFeature extends IFeatureBase {
  action: typeof FEATURES.spinner;
  data:
    | {
        show: true;
        timeout: number;
      }
    | {
        show: false;
      };
}

/**
 * the given URL cannot goBack, close the WebView
 */
export interface INavCloseFeature extends IFeatureBase {
  action: typeof FEATURES.navClose;
  data: Record<string, unknown>;
}

/**
 * opens the given URL in the Navigation Focus Flow
 */
export interface INavFocusFeature extends IFeatureBase {
  action: typeof FEATURES.navFocus;
  data:
    | {
        show: true;
        url: string;
        title: string;
      }
    | {
        show: false; // use navClose instead
      };
}

/**
 * opens the given URL in the Modal nav flow
 */
export interface INavModalFeature extends IFeatureBase {
  action: typeof FEATURES.navModal;
  data:
    | {
        show: true;
        url: string;
        title: string;
      }
    | {
        show: false; // use navClose instead
      };
}

/**
 * opens the given URL in the Navigation Standard Flow
 * @param data.tab This should be left as `undefined` in most cases. If there is
 * a requirement to switch tab before performing a standard nav, a tab can
 * be specified here.
 * app.
 */
export interface INavStandardFeature extends IFeatureBase {
  action: typeof FEATURES.navStandard;
  data: {
    url: string;
    tab?: Tab;
  };
}

/**
 * opens the given URL in the Navigation Tab Flow - optional params to pass to
 * tab
 */
export interface INavTabFeature extends IFeatureBase {
  action: typeof FEATURES.navTab;
  data: {
    tab: Tab;
    params?: {
      url?: string;
      caid?: string;
    };
  };
}

/**
 * opens the given URL in the Navigation External Flow
 * @param data.externalBrowser By default links will open in-app using
 * Safari View Controller (iOS) or Custom Tab (android). If true is passed here
 * the link will be thrown out to the full mobile browser and opened outside the
 * app.
 */
export interface INavExternalFeature extends IFeatureBase {
  action: typeof FEATURES.navExternal;
  data: {
    url: string;
    externalBrowser?: boolean;

    // when true same as navExternalAuth, this is added to have backwards compatibility
    // in Odin dynamicConfig to support navExternalAuth with a fallback of navExternal
    auth?: boolean;
  };
}
/**
 * opens the given URL in the Navigation External Flow
 * @param data.externalBrowser By default links will open in-app using
 * Safari View Controller (iOS) or Custom Tab (android). If true is passed here
 * the link will be thrown out to the full mobile browser and opened outside the
 * app.
 */
export interface INavExternalAuthFeature extends IFeatureBase {
  action: typeof FEATURES.navExternalAuth;
  data: {
    url: string;
    externalBrowser?: boolean;
  };
}

export interface IOpenResourceFeature extends IFeatureBase {
  action: typeof FEATURES.openResource;
  data: IOpenResource["payload"];
}

/** used by @od/daxi, please dont use for other reasons */
export interface IHttpReqError extends IFeatureBase {
  action: typeof FEATURES.httpReqError;
  data: {
    message: string;
    status: number;
    req: any;
  };
}

export interface INavBack extends IFeatureBase {
  action: typeof FEATURES.navBack;
}

/** @deprecated, use IHttpReqError after June, used by @od/daxi only  */
export interface IReauthenticateFeature extends IFeatureBase {
  action: typeof FEATURES.reauthenticate;
  data: {
    message: string;
    status: number;
    req: any;
  };
}

export interface IToastFeature extends IFeatureBase {
  action: typeof FEATURES.toast;
  data: {
    message: string;
  };
}

export interface IShowModalFeature extends IFeatureBase {
  action: typeof FEATURES.showModal;
  data: IShowModal["payload"];
}

export interface ICloseModalFeature extends IFeatureBase {
  action: typeof FEATURES.closeModal;
  data: ICloseModal["payload"];
}

export interface IAsyncMessengerFeature extends IFeatureBase {
  action: typeof FEATURES.asyncMessaging;
  data:
    | {
        userHash: string;
        show: boolean;
      }
    | {
        show: boolean;
      };
}

export interface IPlatformReadinessFeature extends IFeatureBase {
  action: typeof FEATURES.platformReadiness;
  data: IPlatformReadiness["payload"];
}

export interface ILinkEvAccountFeature extends IFeatureBase {
  action: typeof FEATURES.linkEvAccount;
  data: ILinkEvAccount["payload"];
}

export interface IDeviceWalletSupportedFeature extends IFeatureBase {
  action: typeof FEATURES.deviceWalletSupported;
  data: IDeviceWalletSupported["payload"];
}

export interface IDeviceWalletOpenFeature extends IFeatureBase {
  action: typeof FEATURES.deviceWalletOpen;
  data: IDeviceWalletOpen["payload"];
}

export type FeatureList =
  | IViewPdfFeature
  | ISpinnerFeature
  | IRequestAppInfoFeature
  | INavFocusFeature
  | INavModalFeature
  | INavCloseFeature
  | INavExternalFeature
  | INavExternalAuthFeature
  | INavStandardFeature
  | INavTabFeature
  | IHttpReqError
  | IOpenResourceFeature
  | INavBack
  | IFetchAccessTokenFeature
  | IReauthenticateFeature
  | IToastFeature
  | IShowModalFeature
  | ICloseModalFeature
  | IAsyncMessengerFeature
  | IPlatformReadinessFeature
  | ILinkEvAccountFeature
  | IDeviceWalletSupportedFeature
  | IDeviceWalletOpenFeature;

export interface IFeaturesMessage {
  features: FeaturesType[];
}
export interface IAppInfo {
  uniqueId: string;
  bundleId: string;
  version: string;
  versionWithoutBuildNo: string;
  systemVersion: string;
  platform: "ios" | "android" | "web";
  model: string;
  manufacturer: string;
  userAgent: string;
  isEmulator: boolean;
  dynamicConfigVersion: string;
  metadata?: Record<string, string>;
}

export interface IAppInfoResponse {
  appInfo?: IAppInfo;
  features: FeaturesType[];
}

export interface INativeFeatures {
  appInfo?: IAppInfo;
  features: FeatureDetectionType;
}
